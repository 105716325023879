export const generateAppConfig = () =>{
    let exportResult = {
        appRepoLocation:"https://app-test.ombakdiveresort.com.my",
        backendUrl: "http://localhost:3100",
        jsReportUrl: "http://localhost:5488",
        travelAgentBackendUrl: "http://localhost:3000",
        adminRepoLocation: "http://localhost:3000",
    };
    if(process.env.REACT_APP_PRODUCTION_MODE ==='TEST'){
        exportResult.appRepoLocation = "https://app.ombak.my";
        exportResult.backendUrl = "https://admin-api.ombak.my";
        exportResult.jsReportUrl= "https://report.ombak.my";
        exportResult.travelAgentBackendUrl =  "https://business-api.ombak.my";
        exportResult.adminRepoLocation = "https://admin.ombak.my";
    }
    if(process.env.REACT_APP_PRODUCTION_MODE ==='PROD'){
        exportResult.appRepoLocation = "https://app.ombak.my";
        exportResult.backendUrl = "https://admin-api.ombak.my";
        exportResult.jsReportUrl= "https://report.ombak.my";
        exportResult.travelAgentBackendUrl =  "https://business-api.ombak.my";
        exportResult.adminRepoLocation = "https://admin.ombak.my";
    }
 
    return exportResult;
}